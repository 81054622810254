<template>
  <div ref="chatContainer" class="flex flex-col p-4 space-y-4 overflow-y-auto bg-gray-100 h-full">
    <Message
      v-for="message in sortedMessages"
      :key="message.id"
      :message="message"
      :selectedClient="selectedClient"
      :nameAttendant="nameAttendantToShow(message)"
    />
    <div v-if="isClosed" class="text-center text-sm text-gray-500 mt-6 italic">
      Atendimento encerrado
    </div>
  </div>
</template>

<script>
import Message from "./messageHistory.vue";

export default {
  name: "ConversationHistory",
  components: { Message },
  props: {
    messages: { type: Array, required: true },
    selectedClient: { type: String, required: true },
    isClosed: { type: Boolean, default: false }
  },
  computed: {
    sortedMessages() {
      return [...this.messages].sort((a, b) => {
        const dateA = new Date(a.time || a.timestamp);
        const dateB = new Date(b.time || b.timestamp);
        return dateA - dateB;
      });
    }
  },
  methods: {
    scrollToBottom() {
      this.$nextTick(() => {
        if (this.$refs.chatContainer) {
          this.$refs.chatContainer.scrollTop = this.$refs.chatContainer.scrollHeight;
        }
      });
    },
    nameAttendantToShow(message) {
      return !message || message.sender === "client" || !message.attendant
        ? ""
        : message.attendant.name || "Atendente";
    }
  },
  watch: {
    messages: {
      handler() {
        this.scrollToBottom();
      },
      deep: true
    }
  },
  mounted() {
    this.scrollToBottom();
  },
  updated() {
    this.scrollToBottom();
  }
};
</script>

<style scoped>
</style>
