<template>
  <div class="flex max-w-[600px] space-x-4"
    :class="isClient? 'self-start items-start':'self-end items-start flex-row-reverse'">
    <AvatarInitials :name="isClient? selectedClient?.name:(message.attendant?.name||nameAttendant)" size="w-8 h-8"
      bgColor="bg-gray-500" class="flex-shrink-0" :class="isClient? '':'mt-5 ml-3'" />
    <div class="flex flex-col">
      <div v-if="!isClient&&message.attendant?.name" class="flex items-center mb-1 gap-2"
        :class="isClient? 'justify-start text-left':'justify-end text-right'">
        <div v-if="message.type==='template'" class="flex items-center text-sm text-green-700 font-medium">
          <i class="material-icons text-primary text-base mr-1">check_circle</i>
          <span class="bg-green-100 px-2 py-1 rounded-md">Mensagem Template</span>
        </div>
        <p class="text-xs text-gray-700 font-medium mb-0">
          {{message.attendant.name}}
        </p>
      </div>


      <div v-if="message.type==='text'||message.type==='template'||!message.type"
        class="p-3 bg-white rounded-lg shadow-md text-sm text-gray-800 mb-1"
        :class="isClient? 'text-left':'text-right'">
        {{message.text||message.content}}
      </div>

      <div v-else-if="message.type==='audio'"
        class="p-3 bg-white rounded-lg shadow-md flex items-center space-x-3 w-[250px]">
        <button @click="togglePlay" class="w-8 h-8 flex items-center justify-center bg-primary rounded-full text-white">
          <i class="material-icons text-white">{{isPlaying? "pause":"play_arrow"}}</i>
        </button>
        <div class="flex-1 flex flex-col items-center justify-center">
          <input type="range" min="0" :max="audioDuration||1" :value="audioCurrentTime" @input="seekAudio"
            class="w-full h-1 accent-[#6F6F6F] cursor-pointer" />
        </div>
        <span class="text-xs text-gray-600">{{formattedCurrentTime}} | {{formattedTotalTime}}</span>
      </div>

      <div v-else-if="message.type==='media'"
        class="p-3 bg-white rounded-lg shadow-md flex flex-col space-y-2 w-[250px]">
        <template v-if="isImage">
          <img :src="mediaURL" alt="Imagem enviada" class="rounded-lg max-w-full max-h-[200px] object-cover" />
        </template>
        <template v-else-if="isVideo">
          <video controls class="rounded-lg max-w-full max-h-[200px]">
            <source :src="mediaURL" :type="message.content.type" />
            Seu navegador não suporta vídeos.
          </video>
        </template>
        <a :href="mediaURL" :download="message.content.name" class="text-primary text-xl flex items-center space-x-1">
          <i class="material-icons">file_download</i>
        </a>
      </div>

      <div v-else-if="message.type==='file'"
        class="p-3 bg-white rounded-lg shadow-md flex items-center space-x-3 w-[250px]">
        <i class="material-icons text-gray-600 text-2xl">insert_drive_file</i>
        <div class="flex-1 flex flex-col">
          <p class="text-sm text-gray-800 font-medium truncate max-w-[180px]">
            {{message.content.name}}
          </p>
        </div>
        <a :href="fileURL" :download="message.content.name" class="text-primary text-xl">
          <i class="material-icons">file_download</i>
        </a>
      </div>

      <p class="text-xs text-gray-500 mt-1" :class="isClient? 'text-left':'text-right'">
        {{formattedTime}}
      </p>
    </div>
  </div>
</template>


<script>
import AvatarInitials from "./avatarInitials.vue";

export default {
  name: "MessageHistory",
  components: { AvatarInitials },
  props: {
    message: { type: Object, required: true },
    selectedClient: { type: String, required: true },
    nameAttendant: { type: String, default: "Atendente" }
  },
  data() {
    return {
      audio: null,
      isPlaying: false,
      audioDuration: this.message.duration || 0,
      audioCurrentTime: 0
    };
  },
  computed: {
    isClient() {
      return (this.message.sender || this.message.author) === "client";
    },
    formattedTime() {
      const dateValue = this.message.time || this.message.timestamp;
      const date = new Date(dateValue);
      return isNaN(date.getTime()) ? "Data Inválida" : date.toLocaleTimeString("pt-BR", { hour: "2-digit", minute: "2-digit" });
    },
    formattedCurrentTime() {
      return this.formatTime(this.audioCurrentTime);
    },
    formattedTotalTime() {
      return this.formatTime(this.audioDuration);
    },
    audioURL() {
      if (this.message.type === "audio" && this.message.content instanceof Blob) {
        return URL.createObjectURL(this.message.content);
      }
      return null;
    },
    fileURL() {
      if (this.message.type === "file" && this.message.content instanceof File) {
        return URL.createObjectURL(this.message.content);
      }
      return null;
    },
    mediaURL() {
      if (this.message.type === "media" && this.message.content instanceof File) {
        return URL.createObjectURL(this.message.content);
      }
      return null;
    },
    isImage() {
      return this.message.type === "media" && this.message.content.type.startsWith("image/");
    },
    isVideo() {
      return this.message.type === "media" && this.message.content.type.startsWith("video/");
    }
  },
  mounted() {
    if (this.message.type === "audio") {
      this.initAudio();
    }
  },
  methods: {
    initAudio() {
      this.audio = new Audio(this.audioURL);
      this.audio.addEventListener("loadedmetadata", () => {
        if (!isNaN(this.audio.duration) && this.audio.duration !== Infinity) {
          this.audioDuration = this.audio.duration;
        }
      });
      this.audio.addEventListener("timeupdate", () => {
        this.audioCurrentTime = this.audio.currentTime;
      });
      this.audio.addEventListener("ended", () => {
        this.isPlaying = false;
        this.audioCurrentTime = 0;
      });
    },
    togglePlay() {
      if (!this.audio) return;
      if (this.isPlaying) {
        this.audio.pause();
      } else {
        this.audio.play();
      }
      this.isPlaying = !this.isPlaying;
    },
    seekAudio(event) {
      if (this.audio) {
        this.audio.currentTime = event.target.value;
      }
    },
    formatTime(seconds) {
      if (isNaN(seconds) || !isFinite(seconds)) return "00:00";
      const min = Math.floor(seconds / 60);
      const sec = Math.floor(seconds % 60);
      return `${min.toString().padStart(2, "0")}:${sec.toString().padStart(2, "0")}`;
    }
  }
};
</script>

<style scoped></style>
