<template>
  <div class="flex flex-col">
    <div class="flex flex-col border border-gray-300 rounded-lg overflow-hidden">
      <div v-if="isBlocked"
        class="p-4 text-center bg-gray-100 text-red-600 text-sm font-semibold flex items-center justify-center">
        <span>
          A última interação com este cliente foi há mais de 24 horas. <br />
          Para continuar, é necessário <strong>enviar uma mensagem template</strong>.
        </span>
        <div class="ml-2">
          <button @mouseenter="showTooltip=true" @mouseleave="showTooltip=false"
            class="flex items-center justify-center w-6 h-6 rounded-full text-white text-xs font-bold">
            <i class="material-icons text-base text-primary">info</i>
          </button>
          <div v-if="showTooltip"
            class="absolute bottom-14 z-50 bg-white shadow-lg p-2 rounded-lg w-52 text-sm flex end">
            De acordo com as políticas da Meta, após 24 horas da última interação, só é possível enviar mensagens
            aprovadas como templates.
            Selecione um template e preencha as informações necessárias para continuar a conversa.
          </div>
        </div>
      </div>

      <div v-if="isBlocked" class="flex justify-center p-4 text-primary">
        <button @click="showModal=true"
          class="px-4 py-2 border font-semibold rounded-lg transition hover:bg-[--q-primary] hover:text-white"
          :style="{ borderColor: 'var(--primary-color)' }">
          Selecionar Template
        </button>
      </div>

      <textarea v-else ref="messageInput" v-model="message" placeholder="Digite uma mensagem aqui..."
        class="w-full py-3 px-4 border-none resize-none bg-gray-50 text-sm" rows="2">
      </textarea>

      <div class="w-full h-[1px]" style="background-color: #d1d5db;"></div>

      <div class="px-4 py-2 bg-white flex items-center justify-between">
        <div class="flex items-center gap-4">
          <button ref="emojiButton" @click="toggleOption('emoji')"
            class="flex items-center justify-center w-8 h-8 rounded-full hover:bg-gray-100 transition"
            :disabled="isBlocked" aria-label="Selecionar Emoji">
            <i class="material-icons text-gray-600">insert_emoticon</i>
          </button>

          <input type="file" ref="fileInput" @change="handleFileUpload" class="hidden" />
          <button @click="openFileDialog"
            class="flex items-center justify-center w-8 h-8 rounded-full hover:bg-gray-100 transition"
            :disabled="isBlocked" aria-label="Anexar Arquivo">
            <i class="material-icons text-gray-600">attach_file</i>
          </button>

          <input type="file" ref="imageInput" @change="handleImageUpload" class="hidden" accept="image/*,video/*" />
          <button @click="openImageDialog"
            class="flex items-center justify-center w-8 h-8 rounded-full hover:bg-gray-100 transition"
            :disabled="isBlocked" aria-label="Anexar Imagem/Vídeo">
            <i class="material-icons text-gray-600">image</i>
          </button>
        </div>

        <div class="flex items-center gap-4">
          <button @mousedown="startRecording" @mouseup="stopRecording" @mouseleave="stopRecording"
            class="flex items-center justify-center w-8 h-8 rounded-full border hover:shadow-lg" :disabled="isBlocked"
            :style="{ borderColor: 'var(--q-primary)' }" aria-label="Gravar Áudio">
            <i class="material-icons text-primary">{{isRecording? "stop":"mic"}}</i>
          </button>

          <button @click="sendMessage"
            class="flex items-center justify-center px-4 py-2 text-white rounded-lg transition bg-primary hover:bg-primary-dark"
            :disabled="isBlocked" aria-label="Enviar Mensagem">
            Enviar
          </button>
        </div>
      </div>

      <EmojiPicker ref="emojiPicker" @select="addEmoji" :native="true" v-if="activeOption==='emoji'"
        class="absolute bottom-14 left-200 z-50 bg-white shadow-lg p-2 rounded-lg" />
    </div>
  </div>
  <TemplateModal v-if="showModal" :contact="contact" :id="id" @close="showModal=false"
    @send-template="handleSendTemplate" />
</template>

<script>
import EmojiPicker from "vue3-emoji-picker";
import "vue3-emoji-picker/css";
import TemplateModal from "./templateModal.vue";

export default {
  name: "MessageInput",
  components: {
    EmojiPicker,
    TemplateModal,
  },
  props: {
    lastMessageTime: String,
    contact: String,
    id: Number,
  },
  data() {
    return {
      message: "",
      activeOption: null,
      isRecording: false,
      mediaRecorder: null,
      audioChunks: [],
      audioDuration: 0,
      startTime: 0,
      isBlocked: false,
      showTooltip: false,
      showModal: false,
    };
  },
  watch: {
    lastMessageTime: {
      immediate: true,
      handler(newVal) {
        this.checkMessageTime(newVal);
      },
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    handleSendTemplate() {
      this.showModal = false;
    },
    toggleOption(option) {
      this.activeOption = this.activeOption === option ? null : option;
    },
    addEmoji(emoji) {
      const input = this.$refs.messageInput;
      const emojiCharacter = emoji.i || emoji.native || emoji.emoji;
      const start = input.selectionStart;
      const end = input.selectionEnd;
      this.message = this.message.slice(0, start) + emojiCharacter + this.message.slice(end);
      this.$nextTick(() => {
        input.focus();
        input.setSelectionRange(start + emojiCharacter.length, start + emojiCharacter.length);
      });
    },
    handleClickOutside(event) {
      const emojiPickerEl = this.$refs.emojiPicker && this.$refs.emojiPicker.$el;
      const emojiButtonEl = this.$refs.emojiButton;
      if (
        this.activeOption === "emoji" &&
        emojiPickerEl &&
        !emojiPickerEl.contains(event.target) &&
        emojiButtonEl &&
        !emojiButtonEl.contains(event.target)
      ) {
        this.activeOption = null;
      }
    },
    openFileDialog() {
      this.$refs.fileInput.click();
    },
    openImageDialog() {
      this.$refs.imageInput.click();
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.$emit("send-message", {
          type: "file",
          content: file,
          fileName: file.name,
          fileSize: file.size,
          fileType: file.type,
        });
      }
    },
    handleImageUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.$emit("send-message", {
          type: "media",
          content: file,
          fileName: file.name,
          fileSize: file.size,
          fileType: file.type,
        });
      }
    },
    checkMessageTime(timestamp) {
      if (!timestamp) {
        this.isBlocked = false;
        return;
      }
      const lastMsgDate = new Date(timestamp);
      const now = new Date();
      const diffHours = (now - lastMsgDate) / (1000 * 60 * 60);
      this.isBlocked = diffHours > 24;
    },
    sendMessage() {
      if (!this.isBlocked && this.message.trim()) {
        this.$emit("send-message", {
          type: "text",
          content: this.message.trim(),
        });
        this.message = "";
      }
    },
    startRecording() {
      navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
        this.mediaRecorder = new MediaRecorder(stream);
        this.audioChunks = [];
        this.audioDuration = 0;
        this.startTime = Date.now();
        this.mediaRecorder.ondataavailable = (event) => {
          this.audioChunks.push(event.data);
        };
        this.mediaRecorder.onstop = () => {
          const endTime = Date.now();
          this.audioDuration = (endTime - this.startTime) / 1000;
          const audioBlob = new Blob(this.audioChunks, { type: "audio/wav" });
          this.$emit("send-message", {
            type: "audio",
            content: audioBlob,
            duration: this.audioDuration,
          });
        };
        this.mediaRecorder.start();
        this.isRecording = true;
      }).catch((error) => {
        console.error("Erro ao acessar o microfone:", error);
      });
    },
    stopRecording() {
      if (this.mediaRecorder && this.isRecording) {
        this.mediaRecorder.stop();
        this.isRecording = false;
      }
    },
  },
};
</script>

<style scoped>
button:focus {
  outline: none;
}

textarea:focus {
  outline: none;
}

.material-icons {
  font-size: 1.5rem;
}

.text-gray-600 {
  color: #999999;
}

.hidden {
  display: none;
}
</style>
