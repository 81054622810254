import { defineStore } from "pinia";
import { apiDev } from "@/services/api";

export const useChatStore = defineStore("chat", {
  state: () => ({
    chats: [],
    loading: false,
    agents: []
  }),

  actions: {
    async fetchChats() {
      this.loading = true;
      try {
        const res = await apiDev.get("/adm/v2/sup/zap/chat");
        const rows = res.data.data?.rows || [];
        this.chats = rows;
      } catch (error) {
        console.error("Error fetching chats:", error);
      } finally {
        this.loading = false;
      }
    },

    async fetchChatById(id) {
      this.loading = true;
      try {
        const res = await apiDev.get(`/adm/v2/sup/zap/chat/${id}?msgCount=200`);
        return res.data;
      } catch (error) {
        console.error("Error fetching chat by ID:", error);
      } finally {
        this.loading = false;
      }
    },

    async fetchContactById(contactId) {
      try {
        const res = await apiDev.get(`/adm/v2/sup/contact/${contactId}`);
        if (res.data.success) {
          return res.data.data;
        } else {
          console.error("Error fetching contact:", res.data.message);
          return null;
        }
      } catch (error) {
        console.error("Error fetching contact by ID:", error);
        return null;
      }
    },

    async fetchContacts() {
      try {
        const res = await apiDev.get("/adm/v2/sup/contact");
        if (res.data.success) {
          return res.data.data?.rows || [];
        } else {
          console.error("Error listing contacts:", res.data.message);
          return [];
        }
      } catch (error) {
        console.error("Error listing contacts:", error);
        return [];
      }
    },

    async updateContact(contactId, payload) {
      this.loading = true;
      try {
        const res = await apiDev.put(`/adm/v2/sup/contact/${contactId}`, payload);
        return res.data;
      } catch (error) {
        console.error("Error updating contact:", error);
        return null;
      } finally {
        this.loading = false;
      }
    },

    async fetchAgents() {
      try {
        const res = await apiDev.get("/adm/v2/sec/user?page=1&perPage=500&isActive=Y&order=name_asc");
        if (res.data.success) {
          const rows = res.data.data?.rows || [];
          this.agents = rows;
          return this.agents;
        } else {
          console.error("Error fetching agents:", res.data.message);
          return [];
        }
      } catch (error) {
        console.error("Error fetching agents:", error);
        return [];
      }
    },

    async processMessage(messagePayload) {
      this.loading = true;
      try {
        const res = await apiDev.post("/adm/v2/sup/zap/webhook", messagePayload);
        return res.data;
      } catch (error) {
        console.error("Error processing message:", error);
        return null;
      } finally {
        this.loading = false;
      }
    },

    async sendMessage(chatId, messageData) {
      this.loading = true;
      try {
        const res = await apiDev.post(`/adm/v2/sup/zap/send/${chatId}`, messageData);
        return res.data;
      } catch (error) {
        console.error("Error sending message:", error);
        return null;
      } finally {
        this.loading = false;
      }
    },

    async updateChat(chatId, updateData) {
      this.loading = true;
      try {
        const res = await apiDev.put(`/adm/v2/sup/zap/chat/${chatId}`, updateData);
        return res.data;
      } catch (error) {
        console.error("Error updating chat:", error);
        return null;
      } finally {
        this.loading = false;
      }
    },

    // 🔁 Aqui estão os métodos que estavam fora de "actions"
    async createContact(payload) {
      this.loading = true;
      try {
        const res = await apiDev.post("/adm/v2/sup/contact", payload);
        return res.data;
      } catch (error) {
        console.error("Error creating contact:", error);
        return null;
      } finally {
        this.loading = false;
      }
    },

    async fetchTemplates() {
      try {
        const res = await apiDev.get("/adm/v2/sup/zap/template");
        if (res.data.success) {
          return res.data.data || [];
        } else {
          console.error("Erro ao listar templates:", res.data.message);
          return [];
        }
      } catch (error) {
        console.error("Erro ao buscar templates:", error);
        return [];
      }
    },

    async fetchTemplateByName(templateName) {
      try {
        const res = await apiDev.get(`/adm/v2/sup/zap/template-details`, {
          params: { name: templateName }
        });
        if (res.data.success) {
          return res.data.data;
        } else {
          console.error("Erro ao buscar template por nome:", res.data.message);
          return null;
        }
      } catch (error) {
        console.error("Erro ao buscar template por nome:", error);
        return null;
      }
    },    

    async sendTemplateMessage(chatId, payload) {
      this.loading = true;
      try {
        const res = await apiDev.post(`/adm/v2/sup/zap/send-template/${chatId}`, payload);
        return res.data;
      } catch (error) {
        console.error("Erro ao enviar template:", error);
        return null;
      } finally {
        this.loading = false;
      }
    }
  }
});
