<template>
  <div class="fixed inset-0 flex items-center justify-center" style="background-color: #00000080; z-index: 50;">
    <div :class="[
      'bg-white rounded-lg shadow-lg',
      selectedTemplate
        ? 'w-[900px] p-6 h-min-screen flex flex-col'
        : 'w-[500px]'
    ]">
      <div class="flex justify-between items-center p-4" :class="selectedTemplate ? 'border-b border-gray-300 pb-4' : ''">
        <h2 class="text-lg font-semibold">Templates disponíveis</h2>
        <button @click="handleBack" class="text-gray-500 hover:text-gray-700">
          <i class="material-icons">close</i>
        </button>
      </div>

      <!-- ETAPA 1 -->
      <div v-if="!selectedTemplate">
        <div class="p-4">
          <div class="relative">
            <div class="w-full flex items-center border border-gray-300 p-3 rounded-md text-gray-600 cursor-pointer"
              @click="toggleDropdown">
              <i class="material-icons text-gray-500 mr-2">search</i>
              <span class="flex-1">
                Selecione o template desejado
              </span>
              <i class="material-icons text-gray-500">arrow_drop_down</i>
            </div>

            <div v-if="isDropdownOpen"
              class="absolute top-full left-0 w-full mt-2 bg-white shadow-lg rounded-md border border-gray-300 z-50 max-h-64 overflow-auto">
              <ul>
                <li v-for="template in templates" :key="template.id" @click="selectTemplate(template)"
                  class="p-3 hover:bg-gray-100 cursor-pointer text-gray-700">
                  {{ template.name }}
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="border-t border-gray-300 mb-3 mx-3"></div>

        <div class="flex justify-end gap-4 items-center p-4">
          <button @click="handleBack"
            class="px-4 py-2 border text-primary font-semibold rounded-lg transition hover:bg-primary hover:text-white"
            :style="{ borderColor: 'var(--primary-color)' }">
            Voltar
          </button>

          <button @click="sendTemplate" :disabled="!isFormValid" class="px-4 py-2 rounded-lg transition"
            :class="isFormValid
              ? 'bg-primary text-white hover:bg-primary-dark'
              : 'bg-grey text-white cursor-not-allowed'">
            Enviar
          </button>
        </div>
      </div>

      <!-- ETAPA 2 -->
      <div v-else class="flex-1 flex flex-col">
        <div class="flex-1 overflow-auto p-4">
          <div class="flex gap-6 w-full h-full">
            <div class="w-2/3 flex flex-col gap-4 overflow-auto">
              <div class="relative">
                <div class="w-full flex items-center border border-gray-300 p-3 rounded-md text-gray-600 cursor-pointer"
                  @click="toggleDropdown2">
                  <i class="material-icons text-gray-500 mr-2">search</i>
                  <span class="flex-1">{{ selectedTemplate.name }}</span>
                  <i class="material-icons text-gray-500">arrow_drop_down</i>
                </div>
                <div v-if="isDropdownOpen2"
                  class="absolute top-full left-0 w-full mt-2 bg-white shadow-lg rounded-md border border-gray-300 z-50 max-h-64 overflow-auto">
                  <ul>
                    <li v-for="template in templates" :key="template.id" @click="selectTemplate(template, true)"
                      class="p-3 hover:bg-gray-100 cursor-pointer text-gray-700">
                      {{ template.name }}
                    </li>
                  </ul>
                </div>
              </div>

              <div v-for="(field, index) in selectedTemplate.fields" :key="index">
                <label class="block text-gray-700 font-medium mb-1">
                  Campo {{ index + 1 }} <span class="text-red">*</span> <span>&#123;{{ field.placeholder }}&#125;</span>
                </label>
                <input type="text" v-model="field.value"
                  class="w-full border border-gray-300 p-2 rounded-md text-gray-600"
                  placeholder="Preencha com a informação desejada" />
              </div>
            </div>

            <div class="w-1/3 bg-gray-100 border border-gray-300 rounded-lg p-4 text-sm text-gray-700 flex flex-col">
              <div class="flex-1 flex items-start justify-center">
                <div class="text-center whitespace-pre-line">
                  <p class="font-semibold mb-2" v-if="selectedTemplate.header">{{ selectedTemplate.header }}</p>
                  <p>{{ formatTemplatePreview(selectedTemplate) }}</p>
                  <p class="text-xs text-gray-500 mt-4" v-if="selectedTemplate.footer">{{ selectedTemplate.footer }}</p>
                </div>
              </div>

              <div v-if="selectedTemplate.id === 3" class="mt-4 flex flex-col items-center space-y-4">
                <button class="px-4 py-2 rounded-md bg-grey text-white w-full">Aceitar</button>
                <button class="px-4 py-2 rounded-md bg-grey text-white w-full">Rejeitar</button>
              </div>
            </div>
          </div>
        </div>

        <div class="border-t border-gray-300 w-[95%] mx-auto mt-4 pt-4 flex justify-end gap-4">
          <button @click="handleBack"
            class="px-4 py-2 border text-primary font-semibold rounded-lg transition hover:bg-primary hover:text-white"
            :style="{ borderColor: 'var(--primary-color)' }">
            Voltar
          </button>

          <button @click="sendTemplate" :disabled="!isFormValid" class="px-4 py-2 rounded-lg transition"
            :class="isFormValid
              ? 'bg-primary text-white hover:bg-primary-dark'
              : 'bg-grey text-white cursor-not-allowed'">
            Enviar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useChatStore } from "@/store/supportPlatform/supportPlatformStore";

export default {
  name: "TemplateModal",
  props: {
    contact: Object,
    id: Number,
  },
  data() {
    return {
      isDropdownOpen: false,
      isDropdownOpen2: false,
      selectedTemplate: null,
      templates: []
    };
  },
  computed: {
    isFormValid() {
      if (!this.selectedTemplate) return false;
      return this.selectedTemplate.fields.every(field => field.value.trim() !== "");
    }
  },
  async created() {
    const chatStore = useChatStore();
    const response = await chatStore.fetchTemplates();
    this.templates = response.map(t => ({ id: t.id, name: t.name }));
  },
  methods: {
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
      this.isDropdownOpen2 = false;
    },
    toggleDropdown2() {
      this.isDropdownOpen2 = !this.isDropdownOpen2;
      this.isDropdownOpen = false;
    },
    async selectTemplate(template, isChange = false) {
      const chatStore = useChatStore();
      const response = await chatStore.fetchTemplateByName(template.name);
      const data = response?.[0];
      if (!data) return;

      const bodyComponent = data.components.find(c => c.type === "BODY");
      const footerComponent = data.components.find(c => c.type === "FOOTER");
      const headerComponent = data.components.find(c => c.type === "HEADER");

      const namedParams = bodyComponent?.example?.body_text_named_params || [];
      const bodyText = bodyComponent?.text || "";

      this.selectedTemplate = {
        id: data.id,
        name: data.name,
        raw: data,
        preview: bodyText,
        header: headerComponent?.text || "",
        footer: footerComponent?.text || "",
        fields: namedParams.map(param => ({
          placeholder: `{{${param.param_name}}}`,
          param_name: param.param_name,
          value: ""
        }))
      };

      if (!isChange) {
        this.isDropdownOpen = false;
      } else {
        this.isDropdownOpen2 = false;
      }
    },
    handleBack() {
      if (this.selectedTemplate) {
        this.selectedTemplate = null;
      } else {
        this.$emit("close");
      }
    },
    async sendTemplate() {
      if (!this.selectedTemplate || !this.isFormValid) return;

      const chatStore = useChatStore();
      const phone = this.contact?.contactInfo?.find(c => c.mainContact === "Y")?.data;
      const chatId = this.id;

      if (!phone || !chatId) return;

      const bodyParams = this.selectedTemplate.fields.map(f => ({
        type: "text",
        text: f.value,
        parameter_name: f.param_name
      }));

      const templateData = JSON.parse(JSON.stringify(this.selectedTemplate.raw));
      const payload = {
        to: phone,
        name: this.selectedTemplate.name,
        language: templateData.language || "pt_BR",
        components: [
          {
            type: "body",
            parameters: bodyParams
          }
        ],
        template: templateData
      };

      const result = await chatStore.sendTemplateMessage(chatId, payload);
      if (result?.success) {
        this.$emit("close");
      }
    },
    formatTemplatePreview(template) {
      if (!template) return "";
      let text = template.preview;
      template.fields.forEach(field => {
        text = text.replace(field.placeholder, field.value || field.placeholder);
      });
      return text;
    }
  }
};
</script>

<style scoped>
.material-icons {
  font-size: 1.5rem;
}

button {
  outline: none;
}

button:focus {
  outline: none;
  box-shadow: none;
}

input:focus {
  outline: none;
  box-shadow: none;
}

.border-gray-300 {
  border-color: #e2e8f0;
}
</style>
