<template>
  <div class="relative">
    <q-btn
      flat
      round
      class="flex items-center justify-center w-12 h-10 border bg-white border-gray-300 transition rounded"
      @click="toggleFilters"
      aria-label="Abrir Filtros"
    >
      <i class="material-icons text-gray-600">filter_alt</i>
    </q-btn>

    <div
      v-if="showFilters"
      class="absolute top-full mt-2 right-0 bg-white border border-gray-300 rounded-lg shadow-lg w-80 p-4 z-10"
    >
      <div class="flex justify-between items-center mb-4">
        <h2 class="text-lg font-bold text-gray-800">Filtros</h2>
        <q-btn flat round dense @click="toggleFilters">
          <i class="material-icons text-gray-600">close</i>
        </q-btn>
      </div>

      <form @submit.prevent="applyFilters">
        <div class="space-y-3">
          <div>
            <label class="block text-sm text-gray-600 mb-1">Empresa</label>
            <q-select
              v-model="filters.company"
              :options="companies"
              option-label="label"
              option-value="value"
              emit-value
              map-options
              outlined
              use-input
              fill-input="false"
              input-debounce="300"
              :placeholder="filters.company ? '' : 'Selecione ou pesquise uma empresa'"
              class="w-full"
              clearable
            />
          </div>

          <div>
            <label class="block text-sm text-gray-600 mb-1">Atendente</label>
            <q-select
              v-model="filters.attendant"
              :options="attendants"
              option-label="label"
              option-value="value"
              emit-value
              map-options
              outlined
              use-input
              fill-input="false"
              input-debounce="300"
              :placeholder="filters.attendant ? '' : 'Selecione ou pesquise um atendente'"
              class="w-full"
              clearable
            />
          </div>

          <div>
            <label class="block text-sm text-gray-600 mb-1">Data</label>
            <q-input
              v-model="dateRangeString"
              outlined
              class="w-full"
              :placeholder="dateRangeString === '' ? 'Selecione a data ou intervalo' : ''"
            >
              <template v-slot:append>
                <q-icon
                  name="event"
                  class="cursor-pointer"
                  @click="toggleDatePopup"
                />
              </template>
              <q-popup-proxy
                v-model="datePopup"
                cover
                transition-show="scale"
                transition-hide="scale"
              >
                <q-date
                  v-model="filters.dateRange"
                  range
                  mask="YYYY-MM-DD"
                  bordered
                  minimal
                />
              </q-popup-proxy>
            </q-input>
          </div>
        </div>

        <div class="flex justify-between items-center mt-6">
          <q-btn
            flat
            label="Limpar"
            class="text-gray-600 transition"
            @click="clearFilters"
          />
          <q-btn
            unelevated
            label="Aplicar"
            class="px-4 py-2 bg-primary text-white"
            @click="applyFilters"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "FilterButton",
  data() {
    const today = new Date().toISOString().slice(0, 10);
    return {
      showFilters: false,
      datePopup: false,
      filters: {
        company: null,
        attendant: null,
        dateRange: [today, ''],
      },
      companies: [
        { label: "Trisul", value: "trisul" },
        { label: "Eurofarma", value: "eurofarma" },
        { label: "TechCorp", value: "techcorp" },
        { label: "Empresa X", value: "empresa_x" }
      ],
      attendants: [
        { label: "João da Silva", value: "joao_da_silva" },
        { label: "Maria Oliveira", value: "maria_oliveira" },
        { label: "Carlos Souza", value: "carlos_souza" }
      ]
    };
  },
  computed: {
    dateRangeString() {
      if (!this.filters.dateRange || !Array.isArray(this.filters.dateRange)) return "";
      const [start, end] = this.filters.dateRange;
      if (start && end) {
        return `${start} - ${end}`;
      } else if (start) {
        return start;
      }
      return "";
    }
  },
  methods: {
    toggleFilters() {
      if (this.datePopup) {
        this.datePopup = false;
      }
      this.showFilters = !this.showFilters;
    },
    toggleDatePopup() {
      this.datePopup = !this.datePopup;
    },
    applyFilters() {
      console.log("Filtros aplicados:", this.filters);
      if (this.datePopup) {
        this.datePopup = false;
      }
      this.showFilters = false;
    },
    clearFilters() {
      const today = new Date().toISOString().slice(0, 10);
      this.filters = {
        company: null,
        attendant: null,
        dateRange: [today, ''],
      };
    }
  }
};
</script>

<style scoped>
.material-icons {
  font-size: 24px;
}
.q-btn {
  border-radius: 6px;
  border: 1px solid #D3D3D3;
}
</style>
