<template>
  <div
    class="w-[100vw] h-[100vh] flex flex-col items-center justify-center relative"
    :style="`background: ${config.backgroundColor}`"
  >
    <div
      id="login-table"
      class="bg-white p-5 2xl:p-10 max-w-[600px] w-full flex flex-col justify-center items-center rounded-[10px] z-50"
    >
      <div class="z-50 max-w-[200px] mb-[16px] 2xl:mb-[32px]">
        <img class="w-full max-h-[70px]" :src="logo" />
      </div>
      <div class="z-50 max-w-[450px] w-full">
        <div id="title" class="text-center">
          <p class="text-[30px] 2xl:text-[40px] font-bold mb-[5px]">
            {{ $t("login.welcome") }}
          </p>
          <p class="text-[18px] 2xl:text-[22px] text-grey">
            {{ $t("login.loginToContinue") }}
          </p>
        </div>
        <form @submit.prevent="postUser">
          <div id="inputs" class="mt-[30px] 2xl:mt-[50px]">
            <div class="email">
              <p class="text-[18px] 2xl:text-[22px]">
                {{ $t("login.typeUser") }}
              </p>
              <input
                v-model="dataLogin.username"
                class="h-[56px] w-full border border-[#D2D2D2] p-3 pl-[20px] rounded-[10px]"
                type="text"
                required
                :placeholder="$t('login.user')"
              />
            </div>
            <div class="password mt-[40px]">
              <p class="text-[18px] 2xl:text-[22px]">
                {{ $t("login.typePassword") }}
              </p>
              <input
                v-model="dataLogin.password"
                class="h-[56px] w-full border border-[#D2D2D2] p-3 pl-[20px] rounded-[10px]"
                type="password"
                required
                :placeholder="$t('login.password')"
              />
              <p class="mt-1 text-[12px]">
                Esqueceu sua senha?
                <span
                  @click="$router.push(`/login/forgotpwd`)"
                  :style="`color: ${config?.actionColor}`"
                  :class="`cursor-pointer underline`"
                  >Clique aqui!</span
                >
              </p>
            </div>
          </div>
          <div
            id="buttons"
            class="mt-[30px] flex flex-col items-center justify-center"
          >
            <q-btn
              type="submit"
              unelevated
              rounded
              no-caps
              :loading="store.loading"
              :style="`background: ${config?.actionColor}`"
              :class="`hover:brightness-110 transition-all text-[18px] 2xl:text-[22px] text-white max-w-[240px] w-full h-[56px]`"
              >{{ $t("login.buttonEnter") }}
            </q-btn>
            <!-- NÃO APAGAR, USAR FUTURAMENTE PARA LOGIN WITH GOOGLE. -->
            <!-- <q-separator color="#D2D2D2" class="w-full my-3" />
          <q-btn
            unelevated
            rounded
            no-caps
            @click="loginWithGoogle"
            :loading="store.loading"
            style="border: 1px solid #d2d2d2"
            :class="`hover:brightness-110 transition-all text-[1rem] text-grey max-w-[280px] w-full h-[56px]`"
          >
            <q-img
              class="mr-[10px]"
              width="32px"
              height="32px"
              :src="googleIcon"
            ></q-img>
            {{ $t("login.googleButton") }}
          </q-btn> -->
            <p class="mt-[25px] text-[0.75rem]">
              {{ $t("login.dontKnowUs") }}
              <span
                :style="`color: ${config?.actionColor}`"
                :class="`cursor-pointer underline`"
                >{{ $t("login.accessOurWebsite") }}</span
              >
            </p>
            <div class="mt-[15px] flex">
              <q-btn color="grey" class="h-[40px]" outline rounded no-caps
                >{{ languages[language]?.label }}
                <q-img
                  class="ml-[10px]"
                  width="32px"
                  height="32px"
                  :src="languages[language]?.flag"
                ></q-img>
                <q-menu v-model="showLanguageMenu" class="rounded-[10px]">
                  <q-list style="min-width: 270px">
                    <q-item
                      clickable
                      class="flex items-center"
                      v-for="language of languages"
                      :key="language.key"
                      @click="selectLanguage(language.key)"
                    >
                      <q-item-section>{{ language.label }}</q-item-section>
                      <q-img
                        width="32px"
                        height="32px"
                        :src="language.flag"
                      ></q-img>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-btn>
            </div>
          </div>
        </form>
      </div>
      <div id="backgrounds">
        <div class="absolute bottom-0 left-0">
          <svg
            class="z-0 w-[300px] h-[301px] 2xl:w-[451px] 2xl:h-[452px]"
            viewBox="0 0 451 452"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="-0.353553"
              y1="451.646"
              x2="450.646"
              y2="0.646464"
              stroke="white"
            />
            <line
              x1="91.6464"
              y1="451.646"
              x2="438.646"
              y2="104.646"
              stroke="white"
            />
          </svg>
        </div>
        <div class="absolute top-0 right-0">
          <svg
            class="z-0 w-[300px] h-[301px] 2xl:w-[451px] 2xl:h-[452px]"
            width="412"
            height="413"
            viewBox="0 0 412 413"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="326.354"
              y1="0.353553"
              x2="12.3536"
              y2="314.354"
              stroke="white"
            />
            <line
              x1="412.354"
              y1="0.353553"
              x2="0.353548"
              y2="412.354"
              stroke="white"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import brFlag from "@/assets/images/ptbr_flag.png";
import esFlag from "@/assets/images/es_flag.png";
import enFlag from "@/assets/images/en_flag.png";
import googleIcon from "@/assets/images/googleIcon.png";
import { useLoginStore } from "@/store/loginStore";
import CryptoJS from "crypto-js";

export default {
  props: {
    config: { type: Object, default: () => {} },
    language: { type: String, default: "pt" },
    logo: {},
  },
  data() {
    const store = useLoginStore();
    return {
      store,
      dataLogin: {
        username: "",
        password: "",
      },
      brFlag,
      enFlag,
      esFlag,
      googleIcon,
      showLanguageMenu: false,
      languages: {
        pt: {
          flag: brFlag,
          key: "pt",
          label: "Português",
        },
        en: {
          flag: enFlag,
          key: "en",
          label: "English",
        },
        es: {
          flag: esFlag,
          key: "es",
          label: "Español",
        },
      },
    };
  },

  methods: {
    selectLanguage(language) {
      this.$emit("selectLanguage", language);
      this.showLanguageMenu = false;
    },
    async postUser() {
      const key = CryptoJS.enc.Utf8.parse("eztools".padEnd(32, "\0"));
      const iv = CryptoJS.lib.WordArray.random(16);
      const password = this.dataLogin.password;

      const encrypted = CryptoJS.AES.encrypt(password, key, {
        iv: iv,
        mode: CryptoJS.mode.CTR,
        padding: CryptoJS.pad.NoPadding,
      });

      const passwordPayload =
        iv.toString(CryptoJS.enc.Hex) +
        ":" +
        encrypted.ciphertext.toString(CryptoJS.enc.Hex);

      const payload = {
        username: this.dataLogin.username,
        password: passwordPayload,
      };

      await this.store.loginUser(payload);
    },
  },
};
</script>
  
  <style>
</style>