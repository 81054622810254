<template>
  <div class="flex items-center">
    <button
      @click="scrollLeft"
      class="text-gray-500 hover:text-black transition"
      :class="{ 'cursor-not-allowed text-gray-300': !canScrollLeft }"
      :disabled="!canScrollLeft"
    >
      <i class="material-icons">chevron_left</i>
    </button>

    <div
      ref="scrollContainer"
      class="flex overflow-x-auto gap-4 mx-4 flex-1 relative scrollbar-hide"
      @scroll="updateScrollState"
    >
      <div
        v-for="tab in tabs"
        :key="tab.id"
        @click="selectTab(tab.id)"
        class="relative cursor-pointer whitespace-nowrap pb-2"
      >
        <span
          class="text-sm font-medium"
          :class="{
            'text-primary': selectedTab === tab.id,
            'text-black': selectedTab !== tab.id,
          }"
        >
          {{ tab.name }}
          <span
            v-if="tab.count !== undefined"
            class="ml-1 text-sm"
            :class="{
              'text-primary': selectedTab === tab.id,
              'text-gray-500': selectedTab !== tab.id,
            }"
          >
            ({{ tab.count }})
          </span>
        </span>
        <div
          v-if="selectedTab === tab.id"
          class="absolute left-0 right-0 bottom-0 h-[2px] bg-primary rounded-full"
        ></div>
      </div>
    </div>

    <button
      @click="scrollRight"
      class="text-gray-500 hover:text-black transition"
      :class="{ 'cursor-not-allowed text-gray-300': !canScrollRight }"
      :disabled="!canScrollRight"
    >
      <i class="material-icons">chevron_right</i>
    </button>
  </div>
</template>

<script>
export default {
  name: "StatusTabs",
  props: {
    tabs: {
      type: Array,
      required: true,
      default: () => [
        { id: 1, name: "Aguardando", count: 0 },
        { id: 2, name: "Em atendimento", count: 0 },
        { id: 3, name: "Concluído" },
      ],
    },
    selected: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      selectedTab: this.selected,
      canScrollLeft: false,
      canScrollRight: false,
    };
  },
  mounted() {
    this.updateScrollState();
  },
  methods: {
    scrollLeft() {
      const container = this.$refs.scrollContainer;
      if (container) {
        container.scrollBy({ left: -200, behavior: "smooth" });
        setTimeout(this.updateScrollState, 100);
      }
    },
    scrollRight() {
      const container = this.$refs.scrollContainer;
      if (container) {
        container.scrollBy({ left: 200, behavior: "smooth" });
        setTimeout(this.updateScrollState, 100);
      }
    },
    selectTab(id) {
      this.selectedTab = id;
      this.$emit("tab-changed", id);
    },
    updateScrollState() {
      const container = this.$refs.scrollContainer;
      if (container) {
        this.canScrollLeft = container.scrollLeft > 0;
        this.canScrollRight =
          container.scrollLeft < container.scrollWidth - container.clientWidth;
      }
    },
  },
};
</script>

<style scoped>
button {
  outline: none;
}
button:focus {
  outline: none;
  box-shadow: none;
}
.scrollbar-hide {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.material-icons {
  font-size: 24px;
}
</style>
