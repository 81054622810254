<template>
  <div class="relative w-full max-w-md">
    <i class="material-icons absolute left-3 top-1/2 transform -translate-y-1/2 text-[#6B7280]">
      search
    </i>
    <input
      type="text"
      v-model="searchQuery"
      class="w-full pl-10 pr-4 py-2 border rounded-lg text-gray-700 placeholder-gray-400 focus:ring-2 focus:ring-primary focus:outline-none"
      placeholder="Pesquisar..."
      @input="handleInput"
    />
  </div>
</template>

<script>
export default {
  name: "SearchInput",
  emits: ["update:search"],
  data() {
    return {
      searchQuery: "",
    };
  },
  methods: {
    handleInput() {
      this.$emit("update:search", this.searchQuery);
    },
  },
};
</script>

<style scoped>
.material-icons {
  font-size: 24px;
}
</style>
