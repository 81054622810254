<template>
  <div class="relative flex flex-col items-center p-4">
    <button v-if="!isEditing"
      class="absolute top-2 right-2 w-8 h-8 border-2 rounded-lg flex items-center justify-center bg-white text-primary"
      :style="{ borderColor: 'var(--primary-color)' }" @click="onEditToggle">
      <i class="material-icons text-lg">edit</i>
    </button>

    <div v-else class="absolute top-2 right-2 flex items-center space-x-2">
      <button class="w-7 h-7 border-2 rounded-lg flex items-center justify-center bg-white text-grey"
        :style="{ borderColor: 'var(--primary-color)' }" @click="onCancelEdit">
        <i class="material-icons text-lg">close</i>
      </button>
      <button class="w-8 h-8 border-2 rounded-lg flex items-center justify-center bg-primary text-white"
        :style="{ borderColor: 'var(--primary-color)' }" @click="onSaveEdit">
        <i class="material-icons text-lg">check</i>
      </button>
    </div>


    <AvatarInitials :name="user?.name" size="w-20 h-20" text-size="text-4xl" />

    <h2 class="text-lg font-semibold mt-2">{{user?.name}}</h2>
    <p class="text-sm" :style="{ color: '#6B7280', marginBottom: '8px' }">
      {{user.company}}
    </p>
  </div>
</template>

<script>
import AvatarInitials from './avatarInitials.vue';

export default {
  name: "UserProfileCard",
  components: {
    AvatarInitials
  },
  props: {
    user: Object,
    isEditing: Boolean,
    onEditToggle: Function,
    onSaveEdit: Function,
    onCancelEdit: Function,
  },
};
</script>

<style scoped>
button:focus {
  outline: none;
}

.material-icons {
  font-size: 1.2rem;
}
</style>
