<template>
  <div class="relative">
    <button
      @click="toggleDropdown"
      class="flex items-center justify-between w-full px-4 py-2 bg-white border border-gray-300 rounded-full shadow-sm hover:bg-gray-50 transition"
    >
      <span class="font-medium mr-4">{{ selectedChannelName }}</span>
      <i
        class="material-icons transform text-lg"
        :class="{ 'rotate-180': isDropdownOpen }"
      >
        expand_more
      </i>
    </button>

    <div
      v-if="isDropdownOpen"
      class="absolute top-full mt-2 w-full bg-white border border-gray-300 rounded-lg shadow-lg z-10"
    >
      <ul>
        <li
          v-for="channel in channels"
          :key="channel.id"
          @click="selectChannel(channel.id)"
          class="flex items-center justify-between px-4 py-2 cursor-pointer transition"
          :class="{
            'bg-blue-50': selectedChannelId === channel.id,
          }"
          @mouseover="hoverChannel = channel.id"
          @mouseleave="hoverChannel = null"
          :style="{
            backgroundColor: hoverChannel === channel.id ? '#f3f4f6' : ''
          }"
        >
          <span class="text-sm font-medium text-gray-800">{{ channel.name }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChannelSelector",
  props: {
    initialChannelId: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      isDropdownOpen: false,
      hoverChannel: null,
      channels: [
        { id: 1, name: "Canal de Suporte" },
        { id: 2, name: "Canal Financeiro" },
        { id: 3, name: "Canal de Vendas" },
        { id: 4, name: "Canal Técnico" },
        { id: 5, name: "Canal de Marketing" },
      ],
      selectedChannelId: this.initialChannelId,
    };
  },
  computed: {
    selectedChannelName() {
      const channel = this.channels.find(
        (channel) => channel.id === this.selectedChannelId
      );
      return channel ? channel.name : "Selecione um canal";
    },
  },
  methods: {
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    selectChannel(id) {
      this.selectedChannelId = id;
      this.isDropdownOpen = false;
      this.$emit("channel-selected", id);
    },
  },
};
</script>

<style scoped>
button:focus {
  background-color: var(--tw-bg-primary);
  color: var(--tw-text-white);
  outline: none;
}
.material-icons {
  font-size: 1.5rem;
}
</style>
