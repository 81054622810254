<template>
  <div class="relative" :class="size">
    <div class="w-full h-full rounded-full flex items-center justify-center text-white font-bold bg-secondary" :class="textSize">
      {{ mainInitial }}
    </div>
    <div
      v-if="overlayName"
      class="absolute bottom-0 right-0 rounded-full border-2 border-white flex items-center justify-center text-white text-xs font-bold bg-primary"
      :class="[overlaySize, overlayBgColor]"
    >
      {{ overlayInitial }}
    </div>
  </div>
</template>

<script>
export default {
  name: "AvatarInitials",
  props: {
    name: {
      type: String,
      default: "",
    },
    textSize: {
      type: String,
      default: "text-xl",
    },
    overlayName: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "w-10 h-10",
    },
    overlaySize: {
      type: String,
      default: "w-5 h-5",
    },
    overlayBgColor: {
      type: String,
      default: "bg-blue-500",
    },
    bgColor: {
      type: String,
      default: "bg-secondary",
    },
  },
  computed: {
    mainInitial() {
      if (!this.name.trim()) return "";
      return this.name.trim().charAt(0).toUpperCase();
    },
    overlayInitial() {
      if (!this.overlayName.trim()) return "";
      return this.overlayName.trim().charAt(0).toUpperCase();
    },
  },
};
</script>

<style scoped>
</style>
