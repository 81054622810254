<template>
  <div class="h-screen flex bg-gray-100">
    <!-- Conversation List -->
    <div class="w-1/4 border-r border-gray-300 bg-white flex flex-col">
      <div class="p-4 border-b border-gray-300 flex items-center justify-between">
        <ChannelSelector />
        <AddButton @open-modal="handleAddConversation" />
      </div>
      <div class="p-4 flex items-center gap-2">
        <SearchInput @update:search="handleSearch" />
        <FilterButton />
      </div>
      <div class="border-b border-gray-300">
        <StatusTabs :tabs="statusTabs" :selected="selectedTab" @tab-changed="handleTabChange" />
      </div>
      <div class="overflow-y-auto h-full">
        <ConversationPreview v-for="conversation in sortedFilteredConversations" :key="conversation.id"
          :id="conversation.id"
          :userName="conversation.contact? conversation.contact.name+' '+conversation.contact.surname:conversation.name"
          :companyName="conversation.companyName"
          :clientName="conversation.contact? conversation.contact.name+' '+conversation.contact.surname:conversation.name"
          :attendantName="conversation.attendant? conversation.attendant.name:''"
          :lastMessage="getLastMessageText(conversation)" :lastMessageTime="getLastMessageTime(conversation)"
          :unreadCount="conversation.unreadCount" :selected="conversation.id===selectedConversationId"
          @select="handleConversationSelect" />
      </div>
    </div>

    <!-- Chat Area -->
    <div class="w-3/4 h-[95vh] border-r border-gray-300 bg-white flex flex-col">
      <template v-if="selectedConversationId">
        <ChatHeader :client="selectedClient" :agents="availableAgents" :selectedAgentId="selectedAgentId"
          :isSidebarVisible="isSidebarVisible" @assign-agent="handleAssignAgent" @arrow-clicked="toggleSidebar" />
        <div class="flex-1 p-4 overflow-y-auto bg-[#e0e0e0]">
          <Conversation v-if="messages.length" :messages="messages" :selectedClient="selectedClient"
            :attendantAvatar="selectedAttendant? selectedAttendant.photourl:''"
            :nameAttendant="selectedAttendant? selectedAttendant.name:''" :isClosed="isConversationClosed" />
        </div>
        <div class="p-4 border-t border-gray-300">
          <SendMessage @send-message="handleSendMessage" :lastMessageTime="getLastMessageTimeLocal"
            :contact="selectedContact" :id="this.selectedConversationId" />
        </div>
      </template>
      <template v-else>
        <div class="flex-1 flex flex-col items-center justify-center">
          <img src="./components/no-preview.png" alt="No conversation selected" class="max-w-sm mb-4" />
          <p class="text-xl text-gray-600">
            Olá! Você ainda não selecionou nenhuma conversa.
          </p>
        </div>
      </template>
    </div>

    <!-- Sidebar (Profile & Details) -->
    <div v-if="selectedConversationId&&isSidebarVisible" class="w-1/4 h-[95vh] bg-white flex flex-col">
      <div class="flex-1 overflow-auto">
        <UserProfileCard v-if="selectedClient" :user="selectedClient" :isEditing="isEditing"
          :onEditToggle="toggleEditing" :onSaveEdit="saveEdit" :onCancelEdit="cancelEdit"
          class="border-b border-gray-300" />
        <ContactDetailsCard ref="contactDetailsCard" v-if="selectedContact" :contact="selectedContact"
          :isEditing="isEditing" class="border-b border-gray-300" />
        <CompanyInfoCard v-if="selectedCompany" :company="selectedCompany" :isEditing="isEditing"
          class="border-b border-gray-300" />
      </div>
      <div class="p-4">
        <FinishButton class="w-full" :label="finishButtonLabel" @click="handleFinishOrReopenConversation" />
      </div>
    </div>

    <NewContactModal :isOpen="isNewMessageModalOpen" :contacts="contactList" @close="isNewMessageModalOpen=false" />
  </div>
</template>

<script>
import AddButton from "./components/addButton.vue";
import ChannelSelector from "./components/channelSelector.vue";
import ConversationPreview from "./components/conversationPreview.vue";
import FilterButton from "./components/filterButton.vue";
import SearchInput from "./components/searchInput.vue";
import StatusTabs from "./components/statusTabs.vue";
import ChatHeader from "./components/chatHeader.vue";
import Conversation from "./components/conversationHistory.vue";
import SendMessage from "./components/sendMessage.vue";
import UserProfileCard from "./components/userProfileCard.vue";
import ContactDetailsCard from "./components/contactDetailsCard.vue";
import CompanyInfoCard from "./components/companyInfoCard.vue";
import NewContactModal from "./components/newContactModal.vue";
import FinishButton from "./components/finishButton.vue";
import { useChatStore } from "@/store/supportPlatform/supportPlatformStore";

export default {
  name: "SupportPlatformPage",
  components: {
    AddButton,
    ChannelSelector,
    ConversationPreview,
    FilterButton,
    SearchInput,
    StatusTabs,
    ChatHeader,
    Conversation,
    SendMessage,
    UserProfileCard,
    ContactDetailsCard,
    CompanyInfoCard,
    NewContactModal,
    FinishButton,
  },
  data() {
    return {
      selectedConversationId: null,
      selectedClient: null,
      selectedAttendant: null,
      selectedAgentId: null,
      selectedContact: null,
      selectedCompany: null,
      messages: [],
      isConversationClosed: false,
      selectedTab: 2,
      isNewMessageModalOpen: false,
      isSidebarVisible: true,
      isEditing: false,
      availableAgents: [],
      contactList: [],
      socket: null,
      currentAgentId: null,
    };
  },
  computed: {
    filteredConversations() {
      if (!this.chatStore?.chats?.length || this.currentAgentId === null) return [];

      return this.chatStore.chats.filter((c) => {
        const isMine = c.attendantId === this.currentAgentId;

        switch (this.selectedTab) {
          case 1:
            return c.isActive === "N" && c.isFinished === "N";
          case 2:
            return c.isActive === "Y" && c.isFinished === "N" && isMine;
          case 3:
            return c.isFinished === "Y" && c.isActive === "N";
          default:
            return true;
        }
      });
    },
    sortedFilteredConversations() {
      return [...this.filteredConversations].sort((a, b) => {
        const aUnassigned = a.attendantId === null ? 0 : 1;
        const bUnassigned = b.attendantId === null ? 0 : 1;
        if (aUnassigned !== bUnassigned) return aUnassigned - bUnassigned;
        const aTime = new Date(a.lastMessage?.timestamp || 0).getTime();
        const bTime = new Date(b.lastMessage?.timestamp || 0).getTime();
        return bTime - aTime;
      });
    },
    statusTabs() {
      if (this.chatStore?.chats?.length) {
        return [
          {
            id: 1,
            name: "Aguardando",
            count: this.chatStore.chats.filter(c => c.isActive === "N" && c.isFinished === "N").length,
          },
          {
            id: 2,
            name: "Em atendimento",
            count: this.currentAgentId
              ? this.chatStore.chats.filter(c => c.attendantId === this.currentAgentId && c.isActive === "Y" && c.isFinished === "N").length
              : 0,
          },
          {
            id: 3,
            name: "Concluído",
            count: this.chatStore.chats.filter(c => c.isFinished === "Y" && c.isActive === "N").length,
          },
        ];
      }
      return [];
    },
    isSelectedConversationFinished() {
      if (!this.selectedConversationId) return true;
      const conversation = this.chatStore.chats.find(c => c.id === this.selectedConversationId);
      if (!conversation) return true;
      return conversation.isFinish !== undefined ? conversation.isFinish === "Y" : conversation.isFinished === "Y";
    },
    getLastMessageText() {
      return (conversation) => {
        if (conversation.messages && conversation.messages.length) {
          const sorted = [...conversation.messages].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
          return sorted[sorted.length - 1].content;
        }
        return conversation.lastMessage?.content || "Sem mensagens disponíveis";
      };
    },
    getLastMessageTime() {
      return (conversation) => {
        if (conversation.messages && conversation.messages.length) {
          const sorted = [...conversation.messages].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
          return sorted[sorted.length - 1].timestamp;
        }
        return conversation.lastMessage?.timestamp || null;
      };
    },
    getLastMessageTimeLocal() {
      if (!this.messages.length) return null;
      const sorted = [...this.messages].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
      return sorted[sorted.length - 1].timestamp;
    },
    finishButtonLabel() {
      const conversation = this.chatStore.chats.find(c => c.id === this.selectedConversationId);
      if (conversation && conversation.isFinished === "Y" && conversation.isActive === "N") {
        return "Reabrir atendimento";
      }
      return "Finalizar atendimento";
    }
  },
  created() {
    this.chatStore = useChatStore();
    this.chatStore.fetchChats();
    this.chatStore.fetchAgents().then((agents) => this.availableAgents = agents);
    this.chatStore.fetchContacts().then((contacts) => this.contactList = contacts);
    this.decodeCurrentAgentId();
    this.initializeWebSocket();
  },
  beforeUnmount() {
    if (this.socket) {
      this.socket.removeEventListener("message", this.handleIncomingMessage);
      this.socket.close();
    }
  },
  methods: {
    decodeCurrentAgentId() {
      const token = localStorage.getItem("@vueweb:accessToken");
      if (!token) return;

      try {
        const payloadBase64 = token.split('.')[1];
        const decodedPayload = JSON.parse(atob(payloadBase64));

        this.currentAgentId = parseInt(decodedPayload.sub);
      } catch (err) {
        console.error("🚨 Erro ao decodificar JWT:", err);
      }
    },

    initializeWebSocket() {
      const token = localStorage.getItem("@vueweb:accessToken");
      const wsUrl = `wss://sicredidev.eztools.live/ez-ws/?token=${token}`;
      this.socket = new WebSocket(wsUrl);
      this.socket.addEventListener("message", this.handleIncomingMessage);
    },

    handleIncomingMessage(event) {
      const rawData = JSON.parse(event.data);

      if (!this.currentAgentId) return;

      if (rawData.event === "MSG_RECEIVED" || rawData.event === "MSG_SENT") {
        const msg = rawData.content || rawData;
        const chatId = msg.chatId || msg.zapChatsid || rawData.chatId || rawData.zapChatsid;
        const conversation = this.chatStore.chats.find(c => c.id === chatId);

        if (!conversation) return;

        if (conversation.attendantId && conversation.attendantId !== this.currentAgentId) {
          if (this.selectedConversationId === chatId) {
            this.selectedConversationId = null;
            this.messages = [];
          }
          return;
        }

        conversation.lastMessage = msg;
        if (this.selectedConversationId !== chatId) {
          conversation.unreadCount += 1;
        }

        if (this.selectedConversationId === chatId) {
          if (!this.messages.find(m => m.id === msg.id)) {
            this.messages.push(msg);
            this.scrollToBottom();
          }
        }
      } else if (rawData.event === "CHAT_UPD") {
        const chatUpdate = rawData.content;
        let conversation = this.chatStore.chats.find(c => c.id === chatUpdate.id);

        if (conversation) {
          Object.assign(conversation, {
            contact: {
              name: chatUpdate.contact?.name || chatUpdate.name || "Novo contato",
              surname: chatUpdate.contact?.surname || "",
            },
            lastMessage: chatUpdate.lastMessage,
            unreadCount: chatUpdate.unreadCount,
            isActive: chatUpdate.isActive,
            isFinished: chatUpdate.isFinished,
            attendantId: chatUpdate.attendantId || null,
            messages: chatUpdate.messages || conversation.messages,
          });
        } else {
          this.chatStore.chats.push({
            id: chatUpdate.id,
            contact: {
              name: chatUpdate.contact?.name || chatUpdate.name || "Novo contato",
              surname: chatUpdate.contact?.surname || "",
            },
            companyName: "Desconhecido",
            attendantId: chatUpdate.attendantId || null,
            lastMessage: chatUpdate.lastMessage,
            unreadCount: chatUpdate.unreadCount || 0,
            isActive: chatUpdate.isActive,
            isFinished: chatUpdate.isFinished,
            messages: chatUpdate.messages || [],
          });
        }
      } else if (rawData.event === "CONTACT_UPD") {
        const updatedContact = rawData.content;
        this.chatStore.chats.forEach((conversation) => {
          if (conversation.contact && conversation.contact.id === updatedContact.id) {
            conversation.contact = { ...conversation.contact, ...updatedContact };
            if (
              this.selectedContact &&
              this.selectedContact.id === updatedContact.id
            ) {
              this.selectedContact = { ...this.selectedContact, ...updatedContact };
              this.selectedClient.name = `${updatedContact.name || ""} ${updatedContact.surname || ""}`.trim();
            }
          }
        });
      }
    },

    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$el.querySelector(".overflow-y-auto");
        if (container) container.scrollTop = container.scrollHeight;
      });
    },

    handleAddConversation() {
      this.isNewMessageModalOpen = true;
    },

    async handleConversationSelect(conversationId) {
      this.selectedConversationId = conversationId;
      const response = await this.chatStore.fetchChatById(conversationId);
      if (response?.success) {
        const chatData = response.data;
        this.messages = chatData.messages || [];
        const fullName = chatData?.contact ? `${chatData.contact.name} ${chatData.contact.surname}`.trim() : (chatData.name || "").trim();
        this.selectedClient = { name: fullName, avatar: "", company: "" };

        if (chatData.attendant) {
          this.selectedAttendant = { name: chatData.attendant.name, photourl: chatData.attendant.photourl };
          this.selectedAgentId = chatData.attendant.id;
        } else {
          this.selectedAttendant = null;
          this.selectedAgentId = null;
        }

        if (chatData.contact?.id) {
          const contactDetails = await this.chatStore.fetchContactById(chatData.contact.id);
          if (contactDetails) this.selectedContact = contactDetails;
        } else {
          this.selectedContact = chatData.contact;
        }

        this.selectedCompany = {};
      }
    },

    handleAssignAgent(agentId) {
      const conversation = this.chatStore.chats.find(c => c.id === this.selectedConversationId);
      if (conversation) {
        if (!agentId) {
          this.chatStore.updateChat(conversation.id, { attendantId: null, isActive: "N", isFinished: "N" });
          conversation.attendantId = null;
          conversation.attendantName = null;
          conversation.isActive = "N";
          conversation.isFinished = "N";
          this.selectedAttendant = null;
          this.selectedAgentId = null;
        } else {
          const agent = this.availableAgents.find(a => a.id === agentId);
          if (agent) {
            this.chatStore.updateChat(conversation.id, { attendantId: agent.id, isActive: "Y" });
            conversation.attendantId = agent.id;
            conversation.attendantName = agent.name;
            conversation.isActive = "Y";
            conversation.isFinished = "N";
            this.selectedAttendant = { name: agent.name, photourl: agent.photourl };
            this.selectedAgentId = agent.id;
          }
        }
      }
    },

    handleSendMessage(message) {
      if (!this.selectedConversationId) return;

      const conversation = this.chatStore.chats.find(c => c.id === this.selectedConversationId);
      if (conversation) {
        this.chatStore.sendMessage(conversation.id, {
          to: conversation.senderNum,
          type: message.type || "text",
          textBody: message.content,
        });

        if (this.selectedAgentId) {
          this.chatStore.updateChat(conversation.id, {
            attendantId: this.selectedAgentId,
            isActive: "Y",
          });
        }
      }
    },

    handleTabChange(tabId) {
      this.selectedTab = tabId;
    },

    toggleSidebar() {
      this.isSidebarVisible = !this.isSidebarVisible;
    },

    toggleEditing() {
      this.isEditing = !this.isEditing;
    },

    async saveEdit() {
      if (!this.selectedContact?.id) {
        console.error("Contato não identificado para atualização.");
        return;
      }
      const payload = this.$refs.contactDetailsCard.getUpdatedContactPayload();
      const result = await this.chatStore.updateContact(this.selectedContact.id, payload);
      if (result?.success) Object.assign(this.selectedContact, payload);
      this.isEditing = false;
    },

    cancelEdit() {
      this.isEditing = false;
    },

    handleSearch(value) {
      console.log(value);
    },

    async handleFinishOrReopenConversation() {
      const conversation = this.chatStore.chats.find(c => c.id === this.selectedConversationId);
      if (!conversation) return;

      const isReopening = conversation.isFinished === "Y" && conversation.isActive === "N";

      const updateData = isReopening
        ? { isActive: "Y", isFinished: "N" }
        : { isActive: "N", isFinished: "Y", attendantId: null };

      const result = await this.chatStore.updateChat(conversation.id, updateData);

      if (result?.success) {
        conversation.isActive = updateData.isActive;
        conversation.isFinished = updateData.isFinished;

        if (!isReopening) {
          conversation.attendantId = null;
          conversation.attendantName = null;
          this.selectedAttendant = null;
          this.selectedAgentId = null;
        }
      } else {
        console.error("Erro ao atualizar status do atendimento.");
      }
    }
  }
};
</script>


<style scoped>
.border-gray-300 {
  border-color: #e2e8f0;
}
</style>
