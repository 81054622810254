<template>
  <button
    class="bg-primary text-white font-semibold rounded-md px-4 py-2 w-full hover:bg-primary-dark transition-colors"
    @click="handleClick"
    :disabled="disabled"
  >
    {{ label }}
  </button>
</template>

<script>
export default {
  name: "FinishButton",
  props: {
    label: {
      type: String,
      default: "Finalizar atendimento",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick() {
      if (!this.disabled) {
        this.$emit("finish");
      }
    },
  },
};
</script>

<style scoped>
button:focus {
  outline: none;
}
button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
