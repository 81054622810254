<template>
  <button
    @click="handleClick"
    class="flex items-center justify-center w-10 h-10 rounded-lg bg-primary text-white shadow-md hover:shadow-lg transition"
    aria-label="Adicionar"
  >
    <i class="material-icons text-xl">add</i>
  </button>
</template>

<script>
export default {
  name: "AddButton",
  emits: ["open-modal"],
  methods: {
    handleClick() {
      this.$emit("open-modal");
    },
  },
};
</script>

<style scoped>
button {
  outline: none;
}
button:focus {
  outline: none;
  box-shadow: none;
}
</style>
