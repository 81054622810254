<template>
  <q-card
    class="relative flex items-center p-3 gap-4 cursor-pointer transition-all"
    :class="{ 'bg-[#E6E6E6]': selected }"
    @click="selectConversation"
    flat
  >
    <div v-if="selected" class="absolute left-0 top-0 bottom-0 w-1 bg-primary"></div>
    <AvatarInitials
      :name="userName"
      :overlayName="attendantName"
      size="w-10 h-10"
      bgColor="bg-green-600"
      overlaySize="w-5 h-5"
    />
    <div class="flex-1">
      <div class="flex justify-between items-center relative">
        <p class="font-bold text-black truncate" style="max-width: 70%;">
          {{ userName }}
        </p>
        <p class="text-xs text-black font-light whitespace-nowrap absolute right-0">
          {{ formattedTime }}
        </p>
      </div>
      <p class="text-xs text-[#718096] truncate" style="margin-top: 0.25rem;">
        {{ companyName || "Desconhecido" }}
      </p>
      <p
        class="text-sm text-[#4A5568]"
        style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; margin-top: 0.5rem;"
        :class="{ 'font-semibold': unreadCount }"
      >
        {{ truncatedMessage }}
      </p>
    </div>
    <q-badge
      v-if="unreadCount"
      :label="unreadCount"
      color="red"
      class="absolute right-4 top-12 text-xs font-semibold flex items-center justify-center shadow-md"
      style="width: 1.5rem; height: 1.5rem; border-radius: 50%;"
    />
  </q-card>
  <div class="w-full h-[1px] bg-[#E2E8F0]"></div>
</template>

<script>
import { QCard, QBadge } from "quasar";
import AvatarInitials from "./avatarInitials.vue";

export default {
  name: "ConversationPreview",
  components: {
    QCard,
    QBadge,
    AvatarInitials,
  },
  props: {
    id: { type: [Number, String], required: true },
    userName: { type: String, default: null },
    attendantName: { type: String, default: "" },
    companyName: { type: String, default: null },
    lastMessage: { type: String, default: null },
    unreadCount: { type: Number, default: 0 },
    lastMessageTime: { type: String, default: null },
    selected: { type: Boolean, default: false },
  },
  emits: ["select"],
  computed: {
    formattedTime() {
      if (!this.lastMessageTime) return "—";
      const date = new Date(this.lastMessageTime);
      const day = date.getDate();
      const month = date.toLocaleString("pt-BR", { month: "short" });
      const formattedMonth = month.charAt(0).toUpperCase() + month.slice(1);
      const hour = date.getHours().toString().padStart(2, "0");
      const minute = date.getMinutes().toString().padStart(2, "0");
      return `${day} ${formattedMonth}, ${hour}h${minute}`;
    },
    truncatedMessage() {
      const maxChars = 25;
      const message = this.lastMessage || "Sem mensagens disponíveis";
      return message.length > maxChars ? message.substring(0, maxChars) + "..." : message;
    },
  },
  methods: {
    selectConversation() {
      this.$emit("select", this.id);
    },
  },
};
</script>

<style scoped>
</style>
