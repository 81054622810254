<template>
  <div class="flex items-center justify-between p-4 border-b border-gray-300 bg-white">
    <div class="flex items-center gap-4">
      <AvatarInitials :name="client?.name" size="w-8 h-8" bgColor="bg-gray-500" class="flex-shrink-0" />
      <p class="font-bold text-black truncate max-w-xs">
        {{client?.name||"Cliente Desconhecido"}}
      </p>
    </div>

    <div class="flex items-center gap-4">
      <AgentSelector :agents="agents" :initialAgentId="selectedAgentId" @assign-agent="handleAssignAgent" />
      <button @click="handleArrowClick"
        class="flex items-center justify-center w-10 h-10 border border-gray-300 bg-white rounded-md hover:shadow transition focus:outline-none"
        aria-label="Botão de seta">
        <i class="material-icons text-gray-500 text-lg transition-transform duration-300"
          :class="{ 'rotate-180': !isSidebarVisible }">
          chevron_right
        </i>
      </button>
    </div>
  </div>
</template>

<script>
import AgentSelector from "./agentSelector.vue";
import AvatarInitials from "./avatarInitials.vue";

export default {
  name: "ChatHeader",
  components: {
    AgentSelector,
    AvatarInitials
  },
  props: {
    client: {
      type: Object,
      required: true,
      default: () => ({ name: "Cliente Desconhecido", avatar: null }),
    },
    agents: {
      type: Array,
      required: true,
      default: () => [],
    },
    selectedAgentId: {
      type: [Number, String],
      default: null,
    },
    isSidebarVisible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      defaultAvatar: "https://via.placeholder.com/40",
    };
  },
  methods: {
    handleAssignAgent(agentId) {
      this.$emit("assign-agent", agentId);
    },
    handleArrowClick() {
      this.$emit("arrow-clicked");
    },
  },
};
</script>

<style scoped>
.material-icons {
  font-size: 1.5rem;
}
</style>
