<template>
  <div class="bg-white pl-4 pr-4">
    <div class="flex justify-between items-center cursor-pointer" @click="isOpen = !isOpen">
      <h3 class="text-md font-semibold">Informações da Empresa</h3>
      <i class="material-icons text-lg transition-transform" :class="{ 'rotate-180': isOpen }">expand_more</i>
    </div>
    <div v-if="isOpen" class="mt-2 space-y-2 text-sm mb-4">
      <div class="flex justify-between">
        <span class="text-[#6B7280]">Nome da empresa:</span>
        <span v-if="!isEditing" class="text-black">{{ company.name ?? "Desconhecido" }}</span>
        <input
          v-else
          type="text"
          v-model="localCompany.name"
          class="border rounded px-2 py-1 w-40 text-black"
        />
      </div>
      <div class="flex justify-between" v-if="company?.createdAt ?? company?.createdAt != null" >
        <span class="text-[#6B7280]">Criado:</span>
        <span class="text-black">{{ company.createdAt }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompanyInfoCard",
  props: {
    company: Object,
    isEditing: Boolean,
  },
  data() {
    return {
      isOpen: true,
      localCompany: {},
    };
  },
  watch: {
    isEditing(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.localCompany = { ...this.company };
      } else if (!newVal && oldVal) {
        Object.assign(this.company, this.localCompany);
      }
    },
  },
  mounted() {
    this.localCompany = { ...this.company };
  },
};
</script>

<style>
input:focus {
  outline: none;
}
</style>
