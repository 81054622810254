<template>
  <div class="relative">
    <button
      @click="toggleDropdown"
      class="flex items-center justify-between w-48 px-4 py-2 bg-white border border-gray-300 rounded-full shadow-sm hover:shadow-md transition focus:outline-none"
    >
      <div class="flex items-center gap-3">
        <AvatarInitials
          :name="selectedAgent ? selectedAgent.name : ''"
          size="w-8 h-8"
          bgColor="bg-blue-500"
        />
        <span class="text-sm font-medium text-gray-800 truncate">
          {{ selectedAgent ? selectedAgent.name : "Não atribuído" }}
        </span>
      </div>
      <i class="material-icons text-gray-500 text-lg">
        {{ isDropdownOpen ? "expand_less" : "expand_more" }}
      </i>
    </button>

    <div
      v-if="isDropdownOpen"
      class="absolute right-0 mt-2 w-48 bg-white border border-gray-300 rounded-lg shadow-lg z-10 max-h-40 overflow-y-auto"
    >
      <ul>
        <li
          class="flex items-center gap-3 px-4 py-2 cursor-pointer hover:bg-gray-100 transition"
          @click="selectAgent(null)"
        >
          <AvatarInitials name="?" size="w-8 h-8" bgColor="bg-gray-500" />
          <span class="text-sm font-medium text-gray-800">Não atribuído</span>
        </li>

        <li
          v-for="agent in agents"
          :key="agent.id"
          @click="selectAgent(agent)"
          class="flex items-center gap-3 px-4 py-2 cursor-pointer hover:bg-gray-100 transition"
        >
          <AvatarInitials :name="agent.name" size="w-8 h-8" bgColor="bg-blue-500" />
          <span class="text-sm font-medium text-gray-800 truncate">
            {{ agent.name }}
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import AvatarInitials from "./avatarInitials.vue";

export default {
  name: "AgentSelector",
  components: {
    AvatarInitials,
  },
  props: {
    agents: {
      type: Array,
      default: () => [],
    },
    initialAgentId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isDropdownOpen: false,
      selectedAgent: null,
    };
  },
  mounted() {
    this.updateSelectedAgent();
  },
  watch: {
    initialAgentId() {
      this.updateSelectedAgent();
    },
    agents() {
      this.updateSelectedAgent();
    },
  },
  methods: {
    updateSelectedAgent() {
      this.selectedAgent =
        this.agents.find((agent) => agent.id === this.initialAgentId) || null;
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    selectAgent(agent) {
      this.selectedAgent = agent;
      this.isDropdownOpen = false;
      this.$emit("assign-agent", agent ? agent.id : null);
    },
  },
};
</script>

<style scoped>
button:focus {
  background-color: var(--tw-bg-primary);
  color: var(--tw-text-white);
  outline: none;
}
.material-icons {
  font-size: 1.5rem;
}
</style>
