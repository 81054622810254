<template>
  <Teleport to="body">
    <div v-if="isOpen" class="fixed inset-0 z-50 flex items-center justify-center"
      style="background-color: rgba(0, 0, 0, 0.5);" @click.self="closeModal">
      <div class="bg-white p-6 rounded-lg shadow-lg w-[500px] max-w-full" @click.stop>
        <div class="flex justify-between items-center pb-3">
          <h2 class="text-xl font-semibold">Nova Mensagem</h2>
          <button @click="closeModal" class="text-gray-500 hover:text-gray-700">
            <i class="material-icons text-2xl">close</i>
          </button>
        </div>

        <div class="relative mt-4">
          <input v-model="search" type="text" placeholder="Pesquise por nome, empresa ou número"
            class="w-full border rounded-lg p-3 pl-12 text-base" />
          <i class="material-icons absolute left-4 top-3 text-[#666666] text-2xl">search</i>
        </div>

        <div class="mt-5" style="max-height: 60vh; overflow-y: auto;">
          <p class="text-sm font-medium text-gray-600 mb-3">Resultados</p>

          <!-- Bloco para Criar Novo Contato -->
          <div class="flex items-center justify-between hover:bg-gray-100 rounded-lg cursor-pointer mb-5 mr-5" @click="createNewContact">
            <div class="flex items-center gap-3">
              <div class="w-14 h-14 bg-gray-300 rounded-full flex items-center justify-center">
                <i class="material-icons text-primary text-3xl">person</i>
              </div>
              <span class="text-base font-medium">Criar novo contato</span>
            </div>
            <button
              class="flex items-center justify-center w-10 h-10 rounded-lg bg-primary text-white shadow-md hover:shadow-lg transition focus"
              aria-label="Adicionar">
              <i class="material-icons text-2xl">add</i>
            </button>
          </div>

          <div v-for="contact in filteredContacts" :key="contact.id"
            class="flex items-center justify-between mb-5 hover:bg-gray-100 rounded-lg cursor-pointer relative mr-5">
            <div class="flex items-center gap-3">
              <AvatarInitials :name="contact?.name" size="w-10 h-10" />
              <div>
                <p class="text-base font-medium">{{ contact?.name }}</p>
                <p class="text-sm text-[#666666]">{{ contact?.company ?? "Desconhecido" }}</p>
              </div>
            </div>

            <button
              class="w-10 h-10 border-2 rounded-lg flex items-center justify-center bg-white text-primary shadow-md hover:shadow-lg transition"
              :style="{ borderColor: 'var(--primary-color)' }">
              <i class="material-icons text-2xl text-primary">send</i>
            </button>
          </div>

        </div>
      </div>
    </div>
  </Teleport>
</template>

<script setup>
import { defineProps, defineEmits, ref, computed } from "vue";
import { useRouter } from "vue-router";
import AvatarInitials from "./avatarInitials.vue";

const props = defineProps({
  isOpen: Boolean,
  contacts: Array,
});

const emit = defineEmits(["close"]);

const router = useRouter();

const search = ref("");

const filteredContacts = computed(() => {
  const query = search.value.toLowerCase();
  return props.contacts.filter(contact =>
    contact.name.toLowerCase().includes(query) ||
    (contact.company && contact.company.toLowerCase().includes(query)) ||
    (contact.number && contact.number.toString().toLowerCase().includes(query))
  );
});

const closeModal = () => emit("close");

const createNewContact = () => {
  router.push("/chatbot-contato");
};
</script>

<style scoped>
button:focus {
  outline: none;
}

input:focus {
  outline: none;
}
</style>
